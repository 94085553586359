.otpBox{
    border:none;
   border-bottom: 3px solid #959595;
    outline:0;
    width:2vw;
    margin:3px;
    text-align: center;
    font: normal normal medium 20px/27px Noto Sans;
letter-spacing: 0px;
color: #333333;
font-size: 20px;
}