.bg-white{
    background:white;
}
.center {       
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height:346px;
        width:715px;
       -webkit-box-shadow: 0 0 10px grey;
        box-shadow: 0 0 10px grey;
        padding: 30px;
      } 

.heading{
  text-align: left;
  font: normal normal 600 24px "Poppins";
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}
      
.Close {
  position: absolute;
  top:4%;
  left:96%;
  height:16px;
  width:16px;
  cursor: pointer;
  background: transparent url("../../../../assets/images/close.svg") 0% 0% no-repeat padding-box;
}   

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
}
.brokenImage{
  width: 303px;
  height: 214px;
  background-image: url("../../../../assets/images/ErrorPageImg.png");
  background-size: cover; 
  background-position: center; 
  margin-top: 30px; 
}
.message{
  text-align: left;
  font: normal normal normal 13px 'Noto Sans';
letter-spacing: 0px;
color: #333333;
margin-left:30px;
}
