.text-right {
  float: right;
}

@media only screen and (min-width: 990px) {
  .paddingTop150 {
    padding-top: 75px;
  }
}

.MuiAccordion-root::before {
  background-color: none;
  height: 0px !important;
}

.MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation0.MuiPaper-rounded {
  background-color: white !important;
  border: 1px solid rgba(128, 128, 128, 0.38) !important;
}

.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation0.MuiPaper-rounded {
  border: none !important;
  background-color: #f5f5f1 !important;
}

.CustomPaper::-webkit-scrollbar {
  display: none;
}

.CustomPaper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.MuiAccordion-root {
  background: rgba(0, 0, 0, 0) !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.MuiAccordion-root.Mui-expanded {
  background: none !important;
  color: black !important;
}

.MuiButton-containedPrimary {
  color: #333 !important;
}

.MuiButton-contained.Mui-disabled {
  background-color: #42babd !important;
  opacity: 0.4 !important;
  color: #333 !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #c6eaeb !important;
}

.MuiButton-containedPrimary:active {
  background-color: #3aa4a7 !important;
}

.MuiOutlinedInput-root {
  border-radius: 0px !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid #42babd !important;
}

.passwordInsTitle {
  font-family: "NotoSans-Regular";
  font-size: 14px;
}

.title {
  margin: 0px 20px;
  font-weight: bolder;
}

.popup-body {
  padding: 10px;
}

.woman-svg {
  height: 230px;
  margin: 0px 0px 0px 70px;
}

.man-svg {
  height: 185px;
}

@media screen and (max-width: 768px) {

  .woman-svg,
  .man-svg {
    display: none;
  }

  .WelcomeTextBlock {
    padding-top: 30px;
  }
}

.LoginForm-Container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Ill_svg,
.popup-container {
  text-align: center;
  margin: 0px 20px 10px 20px;
}

.WelcomeTextBlock {
  text-align: center;
  width: 100%;
  padding-top: 150px;
  color: #555;
  font-family: "Poppins";
}

.WelcomeTextBlock p {
  margin: 10px 0 0px;
  color: #cecece;
  background-color: #333;
}

.WelcomeTextBlock h2 {
  color: #fff;
}

/* .buttonsBlock {
    display: inline-block;
    width: 70%;
    padding: 8px 0 0;
  } */

.buttonsBlock.loginB {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.passwordResetText {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
  font-size: 0.75rem;
  color: #e40000;
}

.modalCover {
  max-width: 500px;
  min-width: 300px;
  max-height: 280px;
  background: #fff;
  margin: 0 20px;

  .modalTitle {
    color: #333;
    font-family: "NotoSans-SemiBold";
  }

  .modalBody {
    padding: 10px 0 0;

    button {
      width: 100%;
      padding: 30px;
      border-radius: 0;
    }

    button {
      width: 100%;
      padding: 30px;
      border-radius: 0;
    }

    .linkButton {
      padding: 6px 8px;
      width: auto;
      color: #42babd !important;
    }
  }
}