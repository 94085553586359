.hide{
	display: none !important;
}
.qr-image-container {
	display: inline-block;
    width: 100%;
    text-align: center;
    height: 187px;
}
.qr-loader-container {
    display: inline-flex;
    width: 100%;
    text-align: center;
    height: 187px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}
.qr-buttons-container {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 0 0 10px 0;
}
.final-app-list{
	display: inline-block;
    width: auto;
}
.vcode-body {
    display: inline-block;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 15px 15% 0 15%;
    position: relative;
}
.custom-button{
    border-radius: 0px !important;
    padding: 10px 20px !important;
    width: 100%;
    max-width: 250px;
}
#otpcode1, #otpcode2 {
    padding-left: 15px;
    letter-spacing: 27px;
    background-image: linear-gradient(270deg,#a9a9a9 70%,hsla(0,0%,100%,0) 0);
    background-position: bottom;
    background-size: 43px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 120%;
    min-width: 120%;
    font-size: 27px;
    font-family: NotoSans-Regular, Arial, Verdana;
    color: #333; 
}
.dialogCustomizedWidth{
    max-width: 350px !important;
}
.custom-Input .MuiPaper-rounded {
    border-radius: 0px;
  }
  
  .custom-Input .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: inherit !important;
  }
  
  .custom-Input.MuiInput-underline:after {
    border-bottom: none !important;
  }

  
  .custom-Input.otp-error{
    background-image: linear-gradient(to left, red 70%, rgba(255, 255, 255, 0) 0%);
  }
  
  .custom-Input.MuiInput-underline:before{
    border-bottom: none !important;
  }
  .vcode-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.vcode-title-container {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-bottom: 0px;
}
.code-error {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
    color: #f00;
    position: absolute;
    bottom: 51px;
    left: 0;
}
.vcode-buttons-container{
    padding-top:30px;
}

.qr-title {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    padding-bottom: 20px;
}

.qr-sub-title {
    font-size: 13px;
    text-align: center;
    padding: 10px 0 0 0;
}

.bold {
    font-weight:bold;
}

.qr-footer {
    display: inline-block;
    width: 100%;
}

.qr-footer-note {
    font-size: 12px;
    text-align: center;
}

.custom-button.custom-button-1 {
    max-width: 150px;
}

.verify-code-formControl {
    box-sizing: border-box;
    width: 255px;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .verify-code-formControl .MuiInputBase-fullWidth{
    position: sticky;
      left: 0;
      width: 100%;
  }

.instructions-head {
    display: inline-block;
    font-size: 15px;
    width: 100%;
    padding-bottom: 10px;
}

.instructions-list ul {
    display: inline-block;
    list-style-type: none;
    font-size: 15px;
    width: 100%;
}

.step-count-text {
    font-weight: bold;
    font-size: 15px;
}

.bold{
    font-weight: bold !important;
}
.qr-main-container {
    display: inline-block;
    width: 100%;
    padding: 0 15px;
}
.instructions-list ul li {
    padding-bottom: 5px;
}