body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* a {
  cursor: pointer;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  font-size: 2rem;
  font-weight: 300;
}

.centerAlign {
  text-align: center;
}

.textBox {
  padding: 10px;
  font-size: 1rem;
  margin: 5px;
}

.options {
  padding: 10px;
}

.logoutBtn {
  width: 10rem;
  height: 2rem;
}

.signInBtn {
  width: 7.5rem;
  height: 2rem;
  padding: 10px;
}

.error {
  position: static;
  float: left;
}

button {
  color: #fff;
  font-size: 1rem;
}

button:disabled {
  background-color: #b9b9b9;
}

button:active {
  transform: translateY(1px);
}

.olHolder {
  top: -20px;
}

@media screen and (max-width: 640px) {
  .olHolder {
    top: 0px;
  }
}

.olClose span {
  width: 22px;
  height: 22px;
  top: 0px;
  display: block;
  position: relative;
  float: right;
  background: url(./assets/images/close.png) no-repeat 0 0;
  cursor: pointer;
}

#close {
  top: -10px;
}

#agreement_container {
  padding-top: 0px;
}

/* Placeholder */
@media screen and (min-width: 601px) {
  ::-webkit-input-placeholder {
    color: white !important;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: white !important;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: white !important;
  }

  :-ms-input-placeholder {
    color: white !important;
  }
}
